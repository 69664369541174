import { createElement, DOMAttributes, DOMElement } from "react"

import { HeadingLevel } from "./types"

export const createHeading = (
  level: HeadingLevel,
  text: string
): DOMElement<DOMAttributes<HTMLHeadingElement>, HTMLHeadingElement> =>
  createElement("h" + level, null, text)

export const formatPence = (amount: number): string =>
  new Intl.NumberFormat("en-GB", {
    currency: "GBP",
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
    style: "currency",
  }).format(amount / 100)
