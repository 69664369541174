import { css } from "@emotion/react"
import styled from "@emotion/styled"
import BuyToLetCalculator from "@heyhabito/btl-calculator"
import {
  Body,
  column,
  ExpandableCard,
  GridRow,
  horizontal,
  typographyStyles,
  vertical,
} from "design-kit"
import React from "react"

import config from "../../config.json"
import AboutCard from "../shared-components/AboutCard"
import { WrappedBase } from "../shared-components/Shared/WrappedBase"
import Footer from "../shared-components/Footer"
import Navbar from "../shared-components/Navbar"
import Hero from "../shared-components/Shared/Hero"
import { amplitude, registerButtonClick } from "../utils/amplitude"

const PAGE_NAME = "buy_to_let_mortgage_calculator"

const BTLCalculatorIndex: React.FunctionComponent = () => (
  <WrappedBase
    canonicalUrl="https://www.habito.com/buy-to-let-mortgage-calculator"
    config={config}
    head={null}
    intercom={true}
    metaDescription="Find out how much you can borrow for your buy-to-let mortgage - we'll also let you know your estimated monthly payments and find you the best rates."
    metaTitle="Buy-To-Let Mortgage Calculator - How Much Can You Borrow?"
    noIndex={false}
    pageName={PAGE_NAME}
  >
    <BTLCalculatorContent />
  </WrappedBase>
)

const Heading2 = styled.h2`
  ${typographyStyles.headline3}

  margin-top: ${vertical.xl};
`

const List = styled.ul`
  ${typographyStyles.body}

  list-style: disc inside;
  margin-left: ${horizontal.s};

  li {
    margin-top: ${vertical.xs};
  }
`

const ContentContainer = styled.div`
  margin: ${vertical.s} 0;
  max-width: 760px;
`

const logClick = (buttonName: string): void => {
  registerButtonClick(PAGE_NAME)(buttonName)
}

const faqs = [
  {
    content: (
      <React.Fragment>
        <Body>
          Increase your property value. Because almost all buy-to-let lenders
          need a deposit of 20% from you, this calculator caps the amount you
          can borrow at 80% of the property value. You might be able to borrow
          more than it shows you: create an account and we'll find out for you.
        </Body>

        <Body
          css={css`
            margin-top: ${vertical.xs};
          `}
        >
          Increase the monthly rent. Lenders usually want your monthly rent to
          be 25% higher than a high 'stress test' interest rate they set to test
          your application. If you're able to charge tenants a higher rent, that
          usually means you can borrow more.
        </Body>
      </React.Fragment>
    ),
    id: "faq_how_to_borrow_more",
    title: "How to borrow more",
  },
  {
    content: (
      <Body>
        Lenders usually want a minimum 20% deposit for buy-to-let properties.
        Your deposit doesn't affect how much you can borrow, but it does open up
        cheaper interest rates for you.
      </Body>
    ),
    id: "faq_what_about_my_deposit",
    title: "What about my deposit",
  },
]

const BTLCalculatorContent: React.FC = () => {
  const [expandedId, setExpandedId] = React.useState<string | null>(null)

  return (
    <React.Fragment>
      <Navbar sticky />

      <main id="main-content">
        <Hero title="Buy-to-let mortgage calculator">
          <Body>
            See how much you could borrow for a buy-to-let mortgage, and what
            your monthly payments would be.
          </Body>
        </Hero>

        <GridRow>
          <div
            css={css`
              ${column({
                widthDesktop: 12,
                widthMobile: 4,
                widthTablet: 6,
              })}
            `}
          >
            <BuyToLetCalculator
              amplitudeClient={amplitude}
              headingLevel={2}
              title="Calculate"
            />

            <Heading2>Ready to explore mortgages?</Heading2>

            <ContentContainer>
              <Body>
                We're a free mortgage broker, here to make getting your
                buy-to-let mortgage simple. Tell us a bit about you and we'll
                pair you with your own personal expert.
              </Body>

              <List
                css={css`
                  ${typographyStyles.body}

                  list-style: disc inside;

                  li {
                    margin-top: ${vertical.xs};
                  }
                `}
              >
                <li>Free forever</li>

                <li>Whole of market</li>

                <li>All online, whenever suits you</li>

                <li>Jargon-free</li>
              </List>
            </ContentContainer>

            <Heading2>How does this buy-to-let calculator work?</Heading2>

            <ContentContainer>
              <Body>This calculator assumes that:</Body>

              <List
                css={css`
                  ${typographyStyles.body}

                  list-style: disc inside;

                  li {
                    margin-top: ${vertical.xs};
                  }
                `}
              >
                <li>You have £25,000+ income a year</li>

                <li>You're not a first time buyer</li>

                <li>You have a deposit of at least 20%</li>
              </List>
            </ContentContainer>

            <ExpandableCard
              items={faqs.map(faq => ({
                ...faq,
                isExpanded: faq.id === expandedId,
              }))}
              onChange={id => {
                if (id === expandedId) {
                  setExpandedId(null)
                } else {
                  logClick(id)

                  setExpandedId(id)
                }
              }}
            />

            <div
              css={css`
                margin: ${vertical.xl} 0;
              `}
            >
              <AboutCard
                content="Buy-to-let mortgage? We make it simple. We search the whole market to find the best deal for you, then handle your application from start to finish. All for free."
                href="/how-can-we-help/new-mortgage"
                title="Habito is a free online mortgage broker"
              />
            </div>
          </div>
        </GridRow>
      </main>

      <Footer />
    </React.Fragment>
  )
}

export default BTLCalculatorIndex
